@tailwind base;
@tailwind components;
@tailwind utilities;

/* Amplitude Font */
/* Light */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/amplitude-light.woff2') format('woff2'),
    url('./assets/fonts/Amplitude/amplitude-light.woff') format('woff');
  font-weight: 300;
}
/* Regular */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/amplitude-regular.woff') format('woff');
  font-weight: 400;
}
/* Medium */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/amplitude-medium.woff') format('woff');
  font-weight: 500;
}
/* Bold */
@font-face {
  font-family: 'Amplitude';
  src: url('./assets/fonts/Amplitude/46501afd-1244-4d1c-8f6b-982a16dbd086-5.woff2')
      format('woff2'),
    url('./assets/fonts/Amplitude/46501afd-1244-4d1c-8f6b-982a16dbd086-3.woff')
      format('woff');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Amplitude', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #31373d;
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  a {
    @apply text-brown-500;
  }
}
